import {DropdownOption} from '~/types/types'

// Order options
export const NEWS_ORDER_OPTION_NEWEST = 'NEWEST'
export const NEWS_ORDER_OPTION_OLDEST = 'OLDEST'
export const NEWS_ORDER_OPTION_LEAST_VIEWS = 'LEAST_VIEWS'
export const NEWS_ORDER_OPTION_MORE_VIEWS = 'MORE_VIEWS'

export const NEWS_ORDER_OPTIONS: DropdownOption[] = [
  {value: NEWS_ORDER_OPTION_NEWEST, text: 'Newest'},
  {value: NEWS_ORDER_OPTION_OLDEST, text: 'Oldest'},
  {value: NEWS_ORDER_OPTION_LEAST_VIEWS, text: 'Least views'},
  {value: NEWS_ORDER_OPTION_MORE_VIEWS, text: 'More views'}
]

// Status
export const NEWS_STATUS_DRAFT = 'DRAFT'
export const NEWS_STATUS_PENDING_APPROVAL = 'PENDING_APPROVAL'
export const NEWS_STATUS_REJECTED = 'REJECTED'
export const NEWS_STATUS_PUBLISHED = 'PUBLISHED'

// Reject reasons
export const NEWS_REJECT_REASON_PLAGIARISM = 'PLAGIARISM'
export const NEWS_REJECT_REASON_MARKET_SPECULATION = 'MARKET_SPECULATION'
export const NEWS_REJECT_REASON_SPAM = 'SPAM'
export const NEWS_REJECT_REASON_OTHERS = 'OTHERS'

export const NEWS_REJECT_REASONS = [
  {id: NEWS_REJECT_REASON_PLAGIARISM, text: 'Plagiarism'},
  {id: NEWS_REJECT_REASON_MARKET_SPECULATION, text: 'Market Speculation'},
  {id: NEWS_REJECT_REASON_SPAM, text: 'Spam'},
  {id: NEWS_REJECT_REASON_OTHERS, text: 'Others'}
]

export const NEWS_DETAIL_SUGGESTED_ARTICLES_NUMBER = 3

export const MAXIMUM_REJECTS_ATTEMPTS = 2

export const NEWS_BANNER_ROTATION_INTERVAL = 5 * 1000
